/**=========================================================
 * Module: Image Cropper
 =========================================================*/

import $ from 'jquery';
// Image cropper
import 'cropper/dist/cropper.min.css';
import 'cropper/dist/cropper.min.js';

function initImageCropperCover() {
  console.log('inizializzo');
  if (!$.fn.cropper) return;

  var $image = $('.img-container-cover > img'),
    $dataX = $('#dataX'),
    $dataY = $('#dataY'),
    $dataHeight = $('#dataHeight'),
    $dataWidth = $('#dataWidth'),
    $dataRotate = $('#dataRotate'),
    options = {
      // data: {
      //   x: 420,
      //   y: 60,
      //   width: 640,
      //   height: 360
      // },
      data: {
        x: parseInt($('input#cover_crop_x').val()),
        y: parseInt($('input#cover_crop_y').val()),
        width: parseInt($('input#cover_crop_width').val()),
        height: parseInt($('input#cover_crop_height').val())
      },
      // strict: false,
      responsive: true,
      // checkImageOrigin: false

      // modal: false,
      // guides: false,
      // highlight: false,
      // background: false,

      autoCrop: true,
      // autoCropArea: 0.5,
      // dragCrop: true,
      // movable: false,
      // rotatable: false,
      zoomable: false,
      // touchDragZoom: false,
      // mouseWheelZoom: false,
      // cropBoxMovable: false,
      // cropBoxResizable: false,
      // doubleClickToggle: false,

      // minCanvasWidth: 320,
      // minCanvasHeight: 180,
      // minCropBoxWidth: 160,
      // minCropBoxHeight: 90,
      // minContainerWidth: 320,
      // minContainerHeight: 180,

      // build: null,
      // built: null,
      // dragstart: null,
      // dragmove: null,
      // dragend: null,
      // zoomin: null,
      // zoomout: null,

      viewMode: 2,
      // rotable: true,
      // dragMode: 'move',
      // autoCropArea: 1,
      // modal: true,
      // guides: true,
      // highlight: true,
      // cropBoxMovable: true,
      // cropBoxResizable: true,
      // toggleDragModeOnDblclick: true,
      // checkCrossOrigin: false,
      aspectRatio: 16 / 9,
      preview: '.img-preview',
      crop: function (data) {
        let e = data.detail;
        $dataX.val(Math.round(e.x));
        $dataY.val(Math.round(e.y));
        $dataHeight.val(Math.round(e.height));
        $dataWidth.val(Math.round(e.width));
        $dataRotate.val(Math.round(e.rotate));
        console.log(e)
        $('input#cover_crop_x').val(Math.round(e.x))
        $('input#cover_crop_y').val(Math.round(e.y))
        $('input#cover_crop_width').val(Math.round(e.width))
        $('input#cover_crop_height').val(Math.round(e.height))
        $('input#cover_crop_rotate').val(e.rotate)
        $('input#cover_crop_scale_x').val(e.scaleX)
        $('input#cover_crop_scale_y').val(e.scaleY)

      }
    };
  $image.hide();

  $image.on({
    'build.cropper': function (e) {
      console.log(e.type);
    },
    'built.cropper': function (e) {
      console.log(e.type);
    },
    'dragstart.cropper': function (e) {
      console.log(e.type, e.dragType);
    },
    'dragmove.cropper': function (e) {
      console.log(e.type, e.dragType);
    },
    'dragend.cropper': function (e) {
      console.log(e.type, e.dragType);
    },
    'zoomin.cropper': function (e) {
      console.log(e.type);
    },
    'zoomout.cropper': function (e) {
      console.log(e.type);
    },
    'change.cropper': function (e) {
      console.log(e.type);
    }
  }).cropper(options);


  // Methods
  $(document.body).on('click', '[data-method]', function () {
    var data = $(this).data(),
      $target,
      result;

    if (!$image.data('cropper')) {
      return;
    }

    if (data.method) {
      data = $.extend({}, data); // Clone a new one

      if (typeof data.target !== 'undefined') {
        $target = $(data.target);

        if (typeof data.option === 'undefined') {
          try {
            data.option = JSON.parse($target.val());
          } catch (e) {
            console.log(e.message);
          }
        }
      }

      result = $image.cropper(data.method, data.option);

      if (data.method === 'getCroppedCanvas') {
        $('#getCroppedCanvasModal').modal().find('.modal-body').html(result);
      }

      if ($.isPlainObject(result) && $target) {
        try {
          $target.val(JSON.stringify(result));
        } catch (e) {
          console.log(e.message);
        }
      }

    }
  }).on('keydown', function (e) {

    if (!$image.data('cropper')) {
      return;
    }

    switch (e.which) {
      case 37:
        e.preventDefault();
        $image.cropper('move', -1, 0);
        break;

      case 38:
        e.preventDefault();
        $image.cropper('move', 0, -1);
        break;

      case 39:
        e.preventDefault();
        $image.cropper('move', 1, 0);
        break;

      case 40:
        e.preventDefault();
        $image.cropper('move', 0, 1);
        break;
    }

  });


  // Import image
  var $inputImage = $('#cover-image'),
    URL = window.URL || window.webkitURL,
    blobURL;

  if (URL) {
    $inputImage.change(function () {
      var files = this.files,
        file;

      if (!$image.data('cropper')) {
        return;
      }

      if (files && files.length) {
        file = files[0];

        if (/^image\/\w+$/.test(file.type)) {
          blobURL = URL.createObjectURL(file);
          $image.one('built.cropper', function () {
            URL.revokeObjectURL(blobURL); // Revoke when load complete
          }).cropper('reset').cropper('replace', blobURL);
          // $inputImage.val('');
          $image.show();
        } else {
          alert('Please choose an image file.');
        }
      }
    });
  } else {
    $inputImage.parent().remove();
  }


  // Options
  $('.docs-options :checkbox').on('change', function () {
    var $this = $(this);

    if (!$image.data('cropper')) {
      return;
    }

    options[$this.val()] = $this.prop('checked');
    $image.cropper('destroy').cropper(options);
  });


  // Tooltips
  // $('[data-toggle="tooltip"]').tooltip();

}

export default initImageCropperCover;
