/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.6
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// import $ from './modules/common/wrapper.js';
import $ from 'jquery';

import initScreenFull from './modules/common/fullscreen';
import initLoadCSS from './modules/common/load-css';
import initNavbarSearch from './modules/common/navbar-search';
import initSidebar from './modules/common/sidebar';
import initTableCheckAll from './modules/common/table-checkall';
import initTriggerResize from './modules/common/trigger-resize';
import {StateToggler, initToggleState} from './modules/common/toggle-state';
import {initCardDismiss, initCardCollapse, initCardRefresh} from './modules/common/card-tools';
import initBootstrap from './modules/common/bootstrap-start';
// import initBootgrid from './modules/tables/bootgrid';
import initDatatables from './modules/tables/datatable';
import './modules/forms/validation';
import initImageCropper from "./modules/forms/imagecrop";
import initImageCropperProfile from "./modules/forms/imagecrop-profile";
import initImageCropperCover from "./modules/forms/imagecrop-cover";
import initImageCropperRace from "./modules/forms/imagecrop-race";
import initSelect2 from './modules/forms/select2'

export default function initApp() {
  // Init modules
  initBootstrap();
  initScreenFull();
  initLoadCSS();
  initNavbarSearch();
  initSidebar();
  initTableCheckAll();
  initTriggerResize();
  initToggleState();
  // card tools
  initCardDismiss();
  initCardCollapse();
  initCardRefresh();

  // initBootgrid();
  initDatatables();
  initImageCropper();
  initImageCropperProfile();
  initImageCropperCover();
  initImageCropperRace();

  // Forms
  initSelect2();
  // Restore body classes
  // -----------------------------------
  var $body = $('body');
  new StateToggler().restoreState($body);

  // enable settings toggle after restore
  $('#chk-fixed').prop('checked', $body.hasClass('layout-fixed'));
  $('#chk-collapsed').prop('checked', $body.hasClass('aside-collapsed'));
  $('#chk-collapsed-text').prop('checked', $body.hasClass('aside-collapsed-text'));
  $('#chk-boxed').prop('checked', $body.hasClass('layout-boxed'));
  $('#chk-float').prop('checked', $body.hasClass('aside-float'));
  $('#chk-hover').prop('checked', $body.hasClass('aside-hover'));

  console.log('jquery version');
  console.log($.fn.jquery);
  // When ready display the offsidebar
  $('.offsidebar.d-none').removeClass('d-none');
  // function readURL(input) {
  //     if (input.files && input.files[0]) {
  //       var reader = new FileReader();
  //
  //       reader.onload = function (e) {
  //         $('#blah').attr('src', e.target.result);
  //       }
  //
  //       reader.readAsDataURL(input.files[0]);
  //     }
  //   }
  //
  //   $("input#gallery_item_image").change(function () {
  //     readURL(this);
  //   });

}
